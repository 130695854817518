@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body {
  background-color: #fff;
}
.modal-footer-alert {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  background: #eceff0;
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1210px;
  }
}
textarea {
  box-sizing: border-box;

  max-width: 100%;
}
@media (max-width: 800px) {
  .modal-dialog {
    max-width: 730px;
    margin: 1.75rem auto;
  }
}
#nav-mobile {
  display: none;
}
.form-group {
  position: relative;
}
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(255, 255, 255, 0);
}

th {
  font-weight: 100;
}
thead th {
  font-weight: bold;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #606468;
}
.card-header {
  padding: 13px;
  margin-bottom: 0;

  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
  color: #fff;
  text-align: left;
  border-top: 8px solid #c9ba88;
}

.card .span-card-titulo {
  font-size: 16px;
  padding: 6px 25px 6px 6px;
  top: 1px;
  position: absolute;
  background-color: #c9ba88;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  font-weight: 550 !important;
}
.card .span-card-titulo:after {
  content: '';

  position: absolute;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #c9ba88;
}
.card-red .span-card-titulo:after {
  content: '';

  position: absolute;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #ce8787;
}
.card .span-card-titulo .img-card {
  width: 28px;
  margin-right: 5px;
}
.card table thead {
  background-color: #848688 !important;
}
.card-body,
.card-header {
  background: #eceff0;
}
.card-body-lista {
  padding: 0.15rem;
}

.login-block {
  background: #0760c2;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(#8cdfff), to(#0760c2));
  background: linear-gradient(to bottom, #8cdfff, #0760c2);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  float: left;
  width: 100%;
  height: 100%;
  padding: 50px 0;
}

.banner-sec {
  background: url(https://static.pexels.com/photos/33972/pexels-photo.jpg)
    no-repeat left bottom;
  background-size: cover;
  min-height: 500px;
  border-radius: 0 10px 10px 0;
  padding: 0;
}
#audiowide {
  font-family: 'Audiowide';
}
#lato {
  font-family: 'Lato';
}
#raleway,
label {
  font-family: 'Raleway';
}
.login-container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
}

.carousel-inner {
  border-radius: 0 10px 10px 0;
}

.carousel-caption {
  text-align: left;
  left: 5%;
}

.login-sec {
  padding: 50px 30px;
  position: relative;
}

.login-sec .copy-text {
  position: absolute;
  width: 80%;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}

.login-sec .copy-text i {
  color: #0760c2;
}

.login-sec .copy-text a {
  color: #0760c2;
}

.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 30px;
  color: #007bff;
}

.login-sec h2:after {
  content: ' ';
  width: 100px;
  height: 5px;
  background: #007bff;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}

.btn-login {
  background: #0760c2;
  color: #fff;
  font-weight: 600;
}

.banner-text {
  width: 70%;
  position: absolute;
  bottom: 40px;
  padding-left: 20px;
  background-color: #0e0e0e6e;
}

.banner-text h2 {
  color: #fff;
  font-weight: 600;
}

.banner-text h2:after {
  content: ' ';
  width: 100px;
  height: 5px;
  background: #fff;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
}

.banner-text p {
  color: #fff;
}

.table td {
  padding: 0.3rem;
  vertical-align: center;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.bg-electron-blue {
  background-color: #0072ca;
  color: #fff;
}
.bg-electron-blue:hover {
  background-color: #0984e3;
}

.nav-pills .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-pills .nav-link,
.nav-pills > .nav-link {
  color: #333;
  background-color: #eee;
}
.nav-pills-modal .nav-link,
.nav-pills-modal > .nav-link {
  color: #333;
  background-color: #6c747d;
}
.bg-gray {
  background-color: #eee;
}

.border-gray {
  border-color: #eee;
}

/* footer da gisele */

.fixar-rodape {
  bottom: 0;
  left: 0;
  height: 40px;
  /*position: fixed;*/
  width: 100%;
  z-index: 200;
}
.fixar-rodape a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
}

a:hover {
  text-decoration: none;
}

.table-hover tbody tr:hover {
  background-color: #545b627a;
}

.modal-fluid .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  padding: 0;
  position: fixed;
}
.modal-fluid .modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.modal-fluid .modal-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #3c7dcf;
  border-radius: 0;
  box-shadow: none;
}
.modal-fluid .modal-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  padding: 10px;
  background: #6598d9;
  border: 0;
}
.modal-fluid .modal-title {
  font-weight: 300;
  font-size: 2em;
  color: #fff;
  line-height: 30px;
}
.modal-fluid .modal-body {
  position: absolute;
  top: 50px;
  bottom: 60px;
  width: 100%;
  font-weight: 300;
  overflow: auto;
}
.modal-fluid .modal-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 60px;
  padding: 10px;
  background: #f1f3f5;
}

label {
  font-weight: bold !important;
}

button {
  font-weight: bold !important;
}
.btn-excluir {
  border-left: 1px solid rgb(114, 115, 118);
}
.bg-51comvc {
  background-color: #c9ba88;
  color: white;
}
.color-51comvc {
  color: #c9ba88;
}

.bg-red {
  background-color: #c98888;
  color: white;
}
.bg-black {
  background-color: #1c1c1c;
  color: white;
}

.btn-status {
  width: 66px;
  height: 31px;
}
.btn-51comvc {
  background-color: #c9ba88;

  color: white;
  width: 108px;
  /* width: 102px;*/
  height: 35px;
}
button .img-btn {
  width: 15px;
  color: white;
  margin-left: 5px;
}
button .img-btn-acao {
  width: 25px;
  color: white;
  margin-left: 5px;
}

/* login */

div.main {
  background: #003641; /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 12+ */ /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    #003641 1%,
    #1c2b5a 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003641', endColorstr='#1c2b5a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  height: calc(100vh);
  width: 100%;
}

[class*='fontawesome-']:before {
  font-family: 'FontAwesome', sans-serif;
}

/* ---------- GENERAL ---------- */

* {
  box-sizing: border-box;
  margin: 0px;
}
*:before,
*:after {
  box-sizing: border-box;
}

body {
  color: #848688;
  font: 87.5%/1.5em 'Open Sans', sans-serif;
  margin: 0;
}

h5 {
  color: #eee;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

input {
  border: none;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  padding: 0;
  -webkit-appearance: none;
}

p {
  line-height: 1.5em;
}

.clearfix_login {
  *zoom: 1;
}
.clearfix_login:before .clearfix_login:after {
  content: ' ';
  display: table;
}
.clearfix_login:after {
  clear: both;
}

.container_login {
  left: 50%;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* ---------- LOGIN ---------- */

#login form {
  width: 250px;
}
#login,
.logo {
  display: inline-block;
  width: 40%;
}
#login {
  /*border-right:1px solid #fff;*/
  padding: 0px 22px;
  width: 100%;
}
.logo {
  color: #fff;
  font-size: 50px;
  line-height: 125px;
}

#login form p span {
  background-color: #000;
  border-radius: 3px 0px 0px 3px;
  color: #efc910;
  display: block;
  float: left;
  height: 50px;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

#login form input {
  height: 50px;
}
fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}
#login form input[type='text'],
input[type='password'] {
  background-color: #fff;
  border-radius: 0px 3px 3px 0px;
  color: #000;
  margin-bottom: 1em;
  padding: 0 16px;
  width: 200px;
}

#login form input[type='submit'] {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: #000;
  color: #efc910;
  font-weight: bold;
  /* margin-bottom: 2em; */
  text-transform: uppercase;
  padding: 5px 10px;
  height: 30px;
}

#login form input[type='submit']:hover {
  background-color: #000;
  color: #fff;
}

#login > p {
  text-align: center;
}

#login > p span {
  padding-left: 5px;
}
.middle {
  display: flex;
  width: 600px;
}

/* fim do login */

li > a {
  font-weight: bold;
}
button {
  font-weight: bold;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #1fa193;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.demo-app {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-top {
  margin: 0 0 3em;
}

.demo-app-calendar {
  margin: 0 auto;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
/*
tooltip {
  position: fixed;
  background: #13d210;
  color: #fff;
  border: 1.3px solid #000000;
  font-size: 15px;
  z-index: 9999;
  margin-top: -50px;
  padding: 2px;
  border-radius: 5px;
  font-weight: bold;
}*/
.centro {
  height: 100px;
  position: absolute;
  top: 66%;
  left: 50%;
  margin-top: -50px;
  margin-left: -39px;
}
.canvas-container {
  height: 60vh;
}

.cor-de-fundo {
  background: #3599e01a;
}

/* NavBar */

/*
    DEMO STYLE
*/
/*
body {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
}
*/
.fild-required {
  font-size: 18px;
}
p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.navbar {
  /* padding: 15px 10px;*/
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  /* perspective: 1500px;*/
}
.logo-51comvc {
  max-width: 150px;
}
.logo-51comvc-mobile {
  max-width: 70px;
}

#sidebar {
  min-width: 200px;
  max-width: 200px;
  background: #e6e7e9;
  color: #a2a3a5;
  margin-left: 15px;
  -webkit-transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

#sidebar.active {
  margin-left: -250px;
  -webkit-transform: rotateY(100deg);
          transform: rotateY(100deg);
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #1c1c1c;
}

#sidebar ul.components {
  padding: 20px 0;
  /* border-bottom: 1px solid #47748b;*/
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
.aside-user {
  border-top: 2px solid #fff;
  padding: 5px 0px 0px 10px;
  text-transform: uppercase;
  display: block;
  font-weight: bold;
  text-align: left;
  font-size: 12px;
  max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#sidebar ul li a:hover {
  color: #848688;
  background: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded='true'] {
  color: #fff;
  background: #c9ba87;
}

a[data-toggle='collapse'] {
  position: relative;
}

.nav-pills .nav-link,
.nav-pills > .nav-link {
  color: #333;
  background-color: #83868a4f;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #c9ba88;
}
.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #eceff0;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: 100%;
  /* padding: 20px;*/
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#sidebarCollapse {
  width: 40px;
  height: 40px;
  background: #000;
  cursor: pointer;
}

#sidebarCollapse span {
  width: 80%;
  height: 2px;
  margin: 0 auto;
  display: block;
  background: #555;
  -webkit-transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}

#sidebarCollapse span:first-of-type {
  -webkit-transform: rotate(45deg) translate(2px, 2px);
          transform: rotate(45deg) translate(2px, 2px);
}
#sidebarCollapse span:nth-of-type(2) {
  opacity: 0;
}
#sidebarCollapse span:last-of-type {
  -webkit-transform: rotate(-45deg) translate(1px, -1px);
          transform: rotate(-45deg) translate(1px, -1px);
}

#sidebarCollapse.active span {
  -webkit-transform: none;
          transform: none;
  opacity: 1;
  margin: 5px auto;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 1024px) {
  .btn-excluir {
    border-left: 0px;
  }
}

@media (max-width: 768px) {
  .form-control {
    font-size: 0.9rem;
  }
  .paddingrl-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .space-around {
    display: flex;
    justify-content: space-around;
  }
  button .img-btn-acao {
    width: 25px;
    color: white;
    margin-left: 0px;
  }

  #nav-mobile {
    display: block;
    background: #1c1c1c;
  }

  #sidebar {
    margin-left: -200px;
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
  }
  #sidebar.active {
    margin-left: 0;
    -webkit-transform: none;
            transform: none;
  }

  #sidebarCollapse span:first-of-type,
  #sidebarCollapse span:nth-of-type(2),
  #sidebarCollapse span:last-of-type {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
    margin: 5px auto;
    background: #fff;
  }
  #sidebarCollapse.active span {
    margin: 0 auto;
  }
  #sidebarCollapse.active span:first-of-type {
    -webkit-transform: rotate(45deg) translate(2px, 2px);
            transform: rotate(45deg) translate(2px, 2px);
  }
  #sidebarCollapse.active span:nth-of-type(2) {
    opacity: 0;
  }
  #sidebarCollapse.active span:last-of-type {
    -webkit-transform: rotate(-45deg) translate(1px, -1px);
            transform: rotate(-45deg) translate(1px, -1px);
  }
}

/* Fim da NavBar */

/* Checkbox ON / OFF */

.material-switch > input[type='checkbox'] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}

.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  width: 70px;
}
.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 24px;
}
.material-switch > input[type='checkbox']:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.material-switch > input[type='checkbox']:checked + label::after {
  background: inherit;
  left: 50px;
}

/* Checkbox */

input[type='checkbox'][name^='checkbox_imagem']:checked + label {
  opacity: 0.5;
}

/*fim do Checkbox*/
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  opacity: 1;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

